import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// Components
import Hero from "../../components/Hero/Hero";
import Features from "../../components/Features/Features";
import Screenshots from "../../components/Screenshots/Screenshots";
import Gift from "../../components/Gift/Gift";
import Footer from "../../components/Footer/Footer";
import AboutUs from "../AboutUs/AboutUs";

const LandingPage = () => {
  const [searchParams] = useSearchParams();

  // useEffect(() => {
  //   const referral = searchParams.get("referral");
  //   if (referral) {
  //     window.location.href = "https://apps.apple.com/app/id1633444164"; // Replace with your App Store link
  //   }
  // }, [searchParams]);

  return (
    <>
      <Hero />
      <AboutUs />
      <Features />
      <Screenshots />
      <Gift />
      <Footer />
    </>
  );
};

export default LandingPage;
