import { useEffect } from "react";

const ReferralRedirect = () => {

    useEffect(() => {
        // Redirect to App Store
        window.location.href = "https://apps.apple.com/app/id1633444164"; // Replace with actual App Store link
    }, []);

    return null; // No UI needed since it's a redirect
};

export default ReferralRedirect;
